import { Field, FormOption } from "../Types";

const field = (title: string, names: string[], type: string, required: boolean = false, options: FormOption[] | null = null, description: string | null = null): Field =>
    ({ title, names, type, required, options, description })

export const keydates = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'keydates', required);
export const richtext = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'richtext', required);
export const uuid = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'uuid', required);
export const view = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'view', required);
export const text = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'text', required);
export const sign = (title: string, names: string[], required: boolean = false): Field => field(title, names, 'sign', required);
export const number = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'number', required);
export const date = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'date', required);
export const file = (title: string, name: string, required: boolean = false): Field => field(title, [name], 'file', required);
export const select = (title: string, name: string, description: string, options: FormOption[] | null, required: boolean = false): Field =>
    field(title, [name], 'select', required, options, description);