import * as React from "react";
import QRCodeScanner from "../util/QRCodeScanner";

export default function Scanner() {
    const onNewScanResult = (decodedText: string, decodedResult: string) => {
        console.log(decodedText, decodedResult);
    };

    return (
        <div className="App">
            <QRCodeScanner
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
            />
        </div>
    );
};