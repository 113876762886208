import * as React from "react";
import Table from "../data/Table";
import { richtext, select, sign, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { useZoneOptions } from "./Zones";
import { useDateRenderer } from "../data/Utils";
import { FormOption } from "../data/Types";
import { Typography, Chip, ColorPaletteProp } from "@mui/joy";
import { Block, CheckRounded } from "@mui/icons-material";

const primary = 'id';
const table = 'handovers';

export enum Status {
    OpenDefects = 'Open Defects',
    Closed = 'Closed'
}

export const statusOptions: FormOption[] = Object.values(Status).map(val => ({ 'id': val, 'label': val }));

function renderDecorator(row: any) {
    return row.created_by ? row.created_by.charAt(0) : '?';
}

function renderItem(row: any) {
    const status: string = row.status ? row.status : 'Open Defects';
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.created_by}
            </Typography>
            <Chip
                sx={{ float: 'right' }}
                variant="soft"
                size="sm"
                startDecorator={
                    {
                        'Open Defects': <Block />,
                        'Closed': <CheckRounded />,
                    }[status]
                }
                color={
                    {
                        'Open Defects': 'warning',
                        'Closed': 'success',
                    }[status] as ColorPaletteProp
                }
            >
                {status}
            </Chip>       
            <Typography level="body-xs">
                {row.title} &bull; {row.zone_name} &bull;
            </Typography>
            <Typography level="body-xs">
                {row.created_at} &bull; {row.updated_at} &bull; {row.acknowledged_by_name}
            </Typography>
        </div>
    );
}

export default function Handovers() {
    const zoneOptions = useZoneOptions();
    const dateRender = useDateRenderer();

    const fields = React.useMemo(() => [
        text('Title', 'title', true),
        select('Zone', 'zone', 'zone_name', zoneOptions, true),
        richtext('Document', 'document', true),
        sign('Acknowledged By', ['acknowledged_by', 'acknowledged_by_name', 'acknowledged_by_auth']),
        select('Status', 'status', 'status', statusOptions, false), // TODO
    ], [zoneOptions]);


    const metadata = React.useMemo(() => ({
        primary, table,
        single: 'handover',
        zone: true,
        renderDecorator,
        renderItem,
        headers: [
            { title: 'Created By', name: 'created_by', width: 200 },
            { title: 'Title', name: 'title', width: 200 },
            { title: 'Zone', name: 'zone_name' },
            { title: 'Status', name: 'status' },
            { title: 'Created At', name: 'created_at', render: dateRender },
            { title: 'Updated At', name: 'updated_at', render: dateRender },
            { title: 'Acknowledged By', name: 'acknowledged_by_name', width: 200 },
        ]
    }), [dateRender]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}