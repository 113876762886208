import React, { PropsWithChildren, Suspense } from "react";
import { useAuth } from "react-oidc-context";
import { EditType, Field, FormOption } from "../data/Types";
import { useLoading, useMessage } from "../App";
import { Input, Card, Typography, Divider, CardContent, CardActions, FormControl, FormLabel, FormHelperText, Button, Skeleton, IconButton, Autocomplete, Avatar, Chip, CardOverflow, Table, Box } from "@mui/joy";
import { Add, Delete, Fingerprint, InfoOutlined } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { useCloseForm, useFormData, useTableData } from "./Table";
import { useFields, useMetadata } from "../store/Context";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { v4 as uuidv4 } from 'uuid';

type ValuesType = {
    [key: string]: string;
}

export function useCloseEditor(setErrors: (arg0: Set<string>) => void) {
    const closeForm = useCloseForm();

    return React.useCallback((reload: boolean) => {
        setErrors(new Set());
        closeForm(reload)
    }, [closeForm, setErrors])
}

type KeyDateListProps = {
    title: string,
    name: string,
    required: boolean,
}

type KeyDate = {
    key: string,
    date: string,
}

function KeyDateList({ title, name, required }: KeyDateListProps) {
    // errors
    // const invalid = React.useMemo(() => errors.has(name), [errors, name]);
    const { values, setValue } = useValues();
    const { [name]: value } = values;
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const readOnly = editType === EditType.SHOW;
    const [keyDates, setKeyDates] = React.useState<KeyDate[] | null>(null);

    React.useEffect(() => {
        setValue(name, row ? row[name] : '')
    }, [name, row, setValue]);

    React.useEffect(() => {
        if (value != null) {
            setKeyDates(value ? JSON.parse(value) : [])
        }
    }, [value])

    const newItem = React.useCallback(() => {
        setKeyDates(kd => kd ? [...kd, { key: '', date: '' }] : [{ key: '', date: '' }])
    }, [])

    return <Box style={{ gridColumn: 'span 2' }}>
        <IconButton
            size="sm"
            variant="outlined"
            color="neutral"
            style={{ float: 'right' }}
            onClick={newItem}
        >
            <Add />
        </IconButton>
        <Typography>{title}{required && ' *'}</Typography>
        <Table>
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Date</th>
                    <th style={{ width: '20px' }}></th>
                </tr>
            </thead>
            <tbody>
                {keyDates && keyDates.map(({ key, date }) => <tr>
                    <td><Input type='text' readOnly={readOnly} value={key} /></td>
                    <td><Input type='date' readOnly={readOnly} value={date} /></td>
                    <td style={{ width: '20px', textAlign: 'center' }}>{readOnly && <Delete />}</td>
                </tr>)}
            </tbody>
        </Table></Box >
}

type SelectProps = {
    title: string,
    name: string,
    options?: FormOption[] | null,
    required: boolean,
}

function Select({ title, name, options, required }: SelectProps) {
    const { errors, values, setValue } = useValues();
    const { [name]: value } = values;
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const readOnly = editType === EditType.SHOW;
    const invalid = React.useMemo(() => errors.has(name), [errors, name]);
    const selected = React.useMemo(
        () => options?.find(opt => String(opt.id) === String(value)) ?? null,
        [options, value])

    React.useEffect(() => {
        setValue(name, row ? row[name] : '')
    }, [name, row, setValue]);

    return options == null ?
        <Skeleton style={{ position: 'relative' }} animation="wave" height={40} /> :
        <TypedControl
            title={title}
            required={required}
            invalid={invalid}>
            <Autocomplete
                value={selected}
                onChange={(_, value) => setValue(name, value?.id ?? '')}
                options={options}
                readOnly={readOnly}
            /></TypedControl>
}

type RichTextProps = {
    title: string,
    name: string,
    required: boolean,
}

function RichText({ title, name, required }: RichTextProps) {
    const { errors, values, setValue } = useValues();
    const { [name]: value } = values;
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const readOnly = editType === EditType.SHOW;
    const invalid = React.useMemo(() => errors.has(name), [errors, name]);

    React.useEffect(() => {
        setValue(name, row ? row[name] : '')
    }, [name, row, setValue]);

    return value != null ? <TypedControl
        title={title}
        span={true}
        invalid={invalid}
        required={required}><div style={{ color: 'black' }}>
            <CKEditor
                onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                        const root = editor.editing.view.document.getRoot();
                        if (root == null) {
                            return
                        }
                        writer.setStyle("height", "200px", root);
                    });
                }}
                disabled={readOnly}
                editor={ClassicEditor}
                data={value}
                onChange={(_, editor) => setValue(name, editor.getData())}
            /></div></TypedControl> : null;
}

type UuidProps = {
    title: string,
    name: string,
    type?: string,
    placeholder?: string,
    required: boolean,
}

function Uuid({ title, name, placeholder, required, type = 'uuid' }: UuidProps) {
    const { errors, values, setValue } = useValues();
    const { [name]: value } = values;
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const readOnly = editType === EditType.SHOW;
    const invalid = React.useMemo(() => errors.has(name), [errors, name]);

    React.useEffect(() => {
        setValue(name, row ? row[name] : required ? uuidv4() : '')
    }, [name, required, row, setValue]);

    return value != null ? <TypedControl
        title={title}
        invalid={invalid}
        required={required}><Input
            readOnly={readOnly}
            type={type}
            value={value}
            onChange={(e: any) => setValue(name, e?.target.value)}
            startDecorator={<Fingerprint />}
            endDecorator={!readOnly && <Button onClick={() => setValue(name, uuidv4())}>Generate</Button>}
            placeholder={placeholder}
        /></TypedControl> : null;
}

type TextProps = {
    title: string,
    name: string,
    type?: string,
    placeholder?: string,
    required: boolean,
    readOnly?: boolean,
}

function Text({ title, name, placeholder, required, readOnly, type = 'text' }: TextProps) {
    const { errors, values, setValue } = useValues();
    const { [name]: value } = values;
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const invalid = React.useMemo(() => errors.has(name), [errors, name]);
    const rowValue = React.useMemo(() => row ? row[name] : '', [name, row]);

    React.useEffect(() => {
        !readOnly && setValue(name, rowValue)
    }, [name, readOnly, row, rowValue, setValue]);

    return value != null || readOnly ? <TypedControl
        title={title}
        invalid={invalid}
        required={required}><Input
            readOnly={readOnly || editType === EditType.SHOW}
            type={type}
            value={readOnly ? rowValue : value}
            onChange={(e: any) => setValue(name, e?.target.value)}
            placeholder={placeholder}
        /></TypedControl> : null;
}

type SignProps = {
    title: string,
    fullName: string,
    authName: string,
    required: boolean,
}

function Sign({ title, fullName, authName, required }: SignProps) {
    const { formData } = useFormData();
    const { row } = formData ?? {};
    const { [fullName]: nameValue, [authName]: authValue } = row;

    return <TypedControl
        title={title}
        invalid={false}
        required={required}><Card
            sx={{
                width: 320,
                maxWidth: '100%',
                boxShadow: 'lg',
            }}
        >
            <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
                <Avatar src="/static/images/avatar/1.jpg" sx={{ '--Avatar-size': '4rem' }} />
                <Chip
                    size="sm"
                    variant="soft"
                    color="primary"
                    sx={{
                        mt: -1,
                        mb: 1,
                        border: '3px solid',
                        borderColor: 'background.surface',
                    }}
                >
                    {authValue}
                </Chip>
                {/* <Typography level="title-lg">{mailValue}</Typography> */}
                <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
                    {nameValue}
                </Typography>
                <CardOverflow sx={{ bgcolor: 'background.level1' }}>
                    {/* <CardActions buttonFlex="1">
                <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
                    <Button>Unsign</Button>
                </ButtonGroup>
            </CardActions> */}
                </CardOverflow>
            </CardContent>
        </Card></TypedControl>
}

type ControlProps = {
    field: Field
}

export function Control({ field }: ControlProps) {
    const { formData } = useFormData();
    const { row, type: editType } = formData ?? {};
    const { title, names, type, options, required, description } = field;

    switch (type) {
        case 'select':
            return editType === EditType.SHOW ? <Text
                title={title}
                name={description ?? ''}
                required={required}
            /> : <Select
                title={title}
                name={names[0]}
                options={options}
                required={required} />
        case 'richtext':
            return <RichText
                title={title}
                name={names[0]}
                required={required} />
        case 'keydates':
            return <KeyDateList
                title={title}
                name={names[0]}
                required={required} />
        case 'sign':
            return !row || !row[names[1]] ? null
                : <Sign
                    title={title}
                    fullName={names[1]}
                    authName={names[2]}
                    required={required} />
        case 'uuid':
            return <Uuid
                title={title}
                name={names[0]}
                required={required}
            />
        case 'view':
            return <Text
                title={title}
                name={names[0]}
                required={required}
                readOnly={true}
            />
        default:
            return <Text
                title={title}
                name={names[0]}
                required={required}
            />
    }
}

type TypedControlProps = {
    title: string,
    invalid: boolean,
    required: boolean,
    span?: boolean,
}

export function TypedControl({ title, children, invalid, required, span = false }: PropsWithChildren<TypedControlProps>) {

    return <FormControl style={{ gridColumn: (span ? 'span 2' : 'default') }} error={invalid}>
        <FormLabel>{title}{required && ' *'}</FormLabel>
        {children}
        {invalid && <FormHelperText>
            <InfoOutlined />
            Please enter a valid {title}
        </FormHelperText>}
    </FormControl>
}

export function Content({ children }: PropsWithChildren) {
    return <CardContent
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))', gap: 1.5, }}>
        {children}
    </CardContent>
}


export type ValueContextType = {
    values: ValuesType,
    setValue: (name: string, value: string) => void,
    errors: Set<string>,
    setErrors: (arg0: Set<string>) => void,

}

export const ValueContext = React.createContext<ValueContextType>({
    values: {},
    errors: new Set<string>(),
    setErrors: () => { },
    setValue: () => { },
});

export function useValues() {
    return React.useContext(ValueContext);
}

export function ValueProvider({ children }: PropsWithChildren) {
    const [values, setValues] = React.useState<ValuesType>({});
    const [errors, setErrors] = React.useState<Set<string>>(new Set());
    const setValue = React.useCallback((name: string, value: string) => {
        setValues(values => ({ ...values, [name]: value == null ? '' : value }));
    }, []);

    const valueContext = React.useMemo(() => ({
        values,
        setValue,
        errors,
        setErrors,
    }), [errors, setValue, values])

    return <ValueContext.Provider value={valueContext}>{children}</ValueContext.Provider>
}

export function StandardForm() {
    const fields = useFields();

    return <ValueProvider>
        <Editor>
            <Content>
                {fields.map(field => <Control key={field.title} field={field} />)}
            </Content>
            <StandardActions />
        </Editor >
    </ValueProvider>
}

export function useValidation(values: ValuesType, setErrors: (arg0: Set<string>) => void) {
    const fields = useFields();

    return React.useCallback(() => {
        const validateField = (field: Field, value: any) => (field.required && !value);
        const err = new Set(fields
            .filter((field: Field) => field.names.some(name => validateField(field, values[name])))
            .map((field: Field) => field.names).flat())
        setErrors(err);
        return err.size === 0;
    }, [fields, setErrors, values])
}

export function useSubmit(values: ValuesType, setErrors: (arg0: Set<string>) => void) {
    const auth = useAuth();
    const { access_token } = auth.user ?? {};
    const { table, primary } = useMetadata();
    const { formData } = useFormData();
    const { type: editType, row } = formData ?? {};
    const validation = useValidation(values, setErrors);
    const message = useMessage();
    const closeEditor = useCloseEditor(setErrors);

    return React.useCallback(() => {
        function filter(value: any) {
            switch (typeof value) {
                case "string":
                    return value === '' ? null : value;
            }
            return value;
        }

        const isEdit = editType === EditType.EDIT;
        let target = table;

        if (row != null && isEdit) {
            target += '/' + encodeURIComponent(row[primary]);
        } else if (isEdit) {
            // Invalid state
            return;
        }

        async function doSubmit() {
            if (!validation()) {
                console.error("Validation failed");
                return;
            }

            const body = JSON.stringify(
                Object.fromEntries(
                    Object.entries(values).map(([k, v]) => [k, filter(v)])));

            const res = await fetch(process.env.REACT_APP_API + '/' + target, {
                method: isEdit ? 'PUT' : 'POST',
                body,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${access_token}`,
                },
            })
            const data = await res.json();
            if (!res.ok) {
                message('danger', data?.message || data?.error || data);
            } else {
                message('success', 'Inserted OK');
                closeEditor(true);
            }
        }

        doSubmit().catch((e) => message('danger', e));
    }, [editType, table, row, primary, validation, values, access_token, message, closeEditor])
}

export function StandardActions() {
    const { formData } = useFormData();
    const { type } = formData ?? {};
    const { values, setErrors } = useValues();
    const submit = useSubmit(values, setErrors);
    const closeEditor = useCloseEditor(setErrors)
    const { actions } = useMetadata()
    const { row } = formData ?? {};
    const { reload } = useTableData();

    return <CardActions sx={{ gridColumn: '1/-1' }}>
        <Suspense fallback={<Skeleton animation="wave" variant="rectangular" width={300} height={50} />}>
            {type !== EditType.NEW && actions?.filter(action => action.showInForm).map(action =>
                <Button key={action.title(row)} variant="solid" color="primary" onClick={() => action.action(row, reload)}>
                    {action.title(row)}
                </Button>
            )}
            <Button color="neutral" variant='outlined' onClick={() => closeEditor(false)}>
                Close
            </Button >
            {
                type !== EditType.SHOW && <Button variant="solid" color="primary" onClick={submit}>
                    Submit
                </Button>
            }
        </Suspense>
    </CardActions>
}

function useCardTitle() {
    const { formData } = useFormData();
    const { type } = formData ?? {};
    return React.useMemo(() => {
        switch (type) {
            case EditType.NEW:
                return "New"
            case EditType.EDIT:
                return "Editing"
            case EditType.SHOW:
                return "Viewing"
        }
    }, [type])
}

export default function Editor({ children }: PropsWithChildren) {
    const cardTitle = useCardTitle();
    const { single, primary } = useMetadata();
    const { setErrors } = useValues();
    const closeEditor = useCloseEditor(setErrors);
    const { rows } = useTableData();
    const { formData, setFormData } = useFormData();
    const { row, type } = formData ?? {};
    const { isLoading } = useLoading();

    // Update row if we reload the table data
    React.useEffect(() => {
        if (!row || !type) return;
        const currentRow = rows.find(c => c[primary] === row[primary]);
        if (!currentRow) return;
        if (row !== currentRow) {
            setFormData({ type, row: currentRow });
        }
    }, [rows, row, type, primary, setFormData])

    return <Card
        variant="outlined"
        sx={{
            maxHeight: 'max-content',
            maxWidth: '100%',
            mx: 'auto',
            display: isLoading ? 'none' : 'flex'
        }}
    >
        <Typography level="title-lg" startDecorator={<InfoOutlined />}>
            <Suspense fallback={<Skeleton animation="wave" />}>{cardTitle} {single}</Suspense>
        </Typography>
        <IconButton
            aria-label="close"
            variant="plain"
            color="neutral"
            size="sm"
            onClick={() => closeEditor(false)}
            sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
        >
            <CloseIcon />
        </IconButton>
        <Divider inset="none" />
        {children}
    </Card>
}