import * as React from "react";
import Table from "../data/Table";
import { keydates, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { Typography } from "@mui/joy";

const fields = [
    text('Email', 'email', true),
    text('Name', 'name', true),
    text('Authorisation', 'authorisation', true),
    keydates('Expiration Dates', 'expiration_dates', false)
];

function renderDecorator(row: any) {
    return row.name ? row.name.charAt(0) : '?';
}

function renderItem(row: any) {
    return <div>
        <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
            {row.name}
        </Typography>
        <Typography level="body-xs">
            {row.authorisation} &bull; {row.email}
        </Typography>
    </div>;
}

const metadata = {
    primary: 'authorisation',
    table: 'users',
    single: 'user',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'Authorisation', name: 'authorisation' },
        { title: 'Name', name: 'name' },
    ]
}

export default function Users() {
    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}