import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FormOption } from "../data/Types";
import { select, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { MetaContext, FieldContext } from "../store/Context";
import { useATAOptions } from "./ATA";
import { useManufacturersOptions } from "./Manufacturers";
import { Typography } from '@mui/joy';

const table = 'partnumbers';
const primary = 'id';
const zone = false;

export function usePartNumberOptions() {
    const [partNumberOptions, setOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setOptions(listData.items.map((item: any) => ({ id: item[primary], label: item['partnumber'] })))
        }
    }, [listData.isLoading, listData.items, setOptions]);
    return partNumberOptions;
}

function renderDecorator(row: any) {
    return row.ata_type ? row.ata_type.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.partnumber}
            </Typography>
            <Typography level="body-xs">
                {row.ata_type} &bull; {row.description} &bull; {row.manufacturer_name}
            </Typography>
        </div>
    );
}

export default function PartNumbers() {
    const ataOptions = useATAOptions();
    const manufacturerOptions = useManufacturersOptions();

    const metadata = React.useMemo(() => ({
        primary, table, zone,
        single: 'partnumber',
        renderDecorator,
        renderItem,
        headers: [
            { title: 'Part Number', name: 'partnumber' },
            { title: 'ATA', name: 'ata_type' },
            { title: 'Description', name: 'description' },
            { title: 'Manufacturer', name: 'manufacturer_name' }
        ]
    }), []);

    const fields = React.useMemo(() => [
        text('Part Number', 'partnumber'),
        select('ATA', 'ata', 'ata_type', ataOptions),
        text('Description', 'description'),
        select('Manufacturer', 'manufacturer', 'manufacturer_name', manufacturerOptions)
    ], [ataOptions, manufacturerOptions]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}