import * as React from 'react'
import { Select, Option } from "@mui/joy"
import { useZoneOptions } from '../views/Zones';
import { useUserProfile } from '../App';

export default function SelectFilter() {
    const zoneOptions = useZoneOptions();
    const { userProfile, setUserSettings } = useUserProfile();
    const { settings } = userProfile ?? {};
    const filterZone = settings?.zone ?? null;

    const updateFilterZone = React.useCallback((zone: string | null) => {
        setUserSettings(settings ? { ...settings, zone } : { zone });
    }, [setUserSettings, settings])

    return <Select
        variant="outlined"
        placeholder='Station'
        value={filterZone}
        disabled={!zoneOptions || zoneOptions.length === 0}
        onChange={(_, val) => updateFilterZone(val)}>
        {zoneOptions && zoneOptions.map(zone =>
            <Option key={zone.id} value={zone.id}>{zone.label}</Option>
        )}
    </Select>
}