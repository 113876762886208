import * as React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useLocation } from 'react-router-dom';
import { apps } from './Menu';
import SelectFilter from './SelectFilter';

export default function Breadcrumb() {
    const path = useLocation();
    const names = path.pathname.split('/');
    const name = `/${names[1]}`
    const [toplevel, items] = apps.find(([tld, items]) => items.find(item => item.path === name)) ?? []
    const current = items?.find(item => item.path === name);


    return <><Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon fontSize="small" />}
            sx={{ pl: 0 }}
        >
            <Link
                underline="none"
                color="neutral"
                href="#some-link"
                aria-label="Home"
            >
                <HomeRoundedIcon />
            </Link>
            <Typography
                color="neutral"
                fontSize={12}
                fontWeight={500}
            >
                {toplevel}
            </Typography>
            <Typography color="primary" fontWeight={500} fontSize={12}>
                {current?.title}
            </Typography>
            {names[2] && <Typography color="primary" fontWeight={500} fontSize={12}>
                Item
            </Typography>}
        </Breadcrumbs>
    </Box>
        <Box
            sx={{
                display: { xs: 'none', sm: 'flex' },
                mb: 1,
                gap: 1,
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' },
                flexWrap: 'wrap',
                justifyContent: 'space-between',
            }}
        >
            <Typography level="h2" component="h1">
                {current?.title}
            </Typography>
            <SelectFilter />
        </Box>
    </>
}