import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FormOption } from "../data/Types";
import { select, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { useZoneOptions } from "./Zones";
import { Typography } from '@mui/joy';

const primary = 'id';
const table = 'locations';
const zone = true;

export function useLocationOptions() {
    const [locationOptions, setOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setOptions(listData.items.map((item: any) => ({ id: item[primary], label: item['location'] })))
        }
    }, [listData.isLoading, listData.items, setOptions])
    return locationOptions;
}

function renderDecorator(row: any) {
    return row.zone ? row.zone.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.location}
            </Typography>
            <Typography level="body-xs">
                {row.description} &bull; {row.zone_name}
            </Typography>
        </div>
    );
}

export default function Locations() {
    const zoneOptions = useZoneOptions();

    const fields = React.useMemo(() => [
        text('Location', 'location', true),
        text('Description', 'description', true),
        select('Zone', 'zone', 'zone_name', zoneOptions, true)
    ], [zoneOptions]);

    const metadata = React.useMemo(() => ({
        primary, table, zone,
        single: 'location',
        renderDecorator,
        renderItem,
        headers: [
            { title: 'Location', name: 'location' },
            { title: 'Description', name: 'description' },
            { title: 'Zone', name: 'zone_name' },
        ]
    }), []);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}