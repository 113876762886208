import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FormOption } from "../data/Types";
import { text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { MetaContext, FieldContext } from "../store/Context";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.manufacturer ? row.manufacturer.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.manufacturer}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'id',
    table: 'manufacturers',
    single: 'manufacturer',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'Manufacturer', name: 'manufacturer' },
    ]
}
const fields = [
    text('Manufacturer', 'manufacturer')
];

export function useManufacturersOptions() {
    const { table, primary, zone } = metadata;
    const [manufacturerOptions, setOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setOptions(listData.items.map((item: any) => ({ id: item[primary], label: item['manufacturer'] })))
        }
    }, [listData.isLoading, listData.items, primary, setOptions])
    return manufacturerOptions;
}

export default function Manufacturers() {
    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}