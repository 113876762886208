import React from "react"

export type FormOption = {
    label?: string,
    id: string
}

export type UserSettings = {
    zone?: string | null
}

export type UserProfile = {
    email: string,
    authorisation: string,
    customer: string,
    name: string,
    settings: UserSettings | null
}

export type UserProfileData = {
    userProfile: UserProfile | null,
    setUserSettings: (arg0: UserSettings | null) => void,
}

export type SearchData = {
    filterSearch: string,
    setFilterSearch: (arg0: string) => void,
}

export type LoadingData = {
    isLoading: boolean,
    setIsLoading: (arg0: boolean) => void,
}

export type TableData = {
    rows: any[],
    reload: () => void,
}

export enum EditType {
    NEW,
    EDIT,
    SHOW
}

export type FormType = {
    type: EditType,
    row?: any
}

export type FormData = {
    formData: FormType | null,
    setFormData: (arg0: FormType | null) => void
}

export type Action = {
    title: (row: any) => string,
    action: (row: any, reload: any) => void,
    showInForm?: boolean
}

export type Header = {
    title: string,
    name: string,
    width?: number,
    render?: (value: string, row: any) => React.ReactElement,
    mapper?: { [k: string]: string }
}

export type Field = {
    title: string,
    type: string,
    names: string[],
    required: boolean,
    options?: FormOption[] | null,
    description?: string | null,
}

type SortOrder = 'asc' | 'desc';

export type SortDescriptor = {
    column: string,
    direction: SortOrder,
}

export enum Status {
    Unverified = 'Unverified',
    Serviceable = 'Serviceable',
    Unserviceable = 'Unserviceable',
    Unsalvageable = 'Unsalvageable',
    Hold = 'Hold'
}

export const statusOptions: FormOption[] = Object.values(Status).map(val => ({ 'id': val, 'label': val }));

export enum Quantity {
    ea = 'ea',
    ltr = 'ltr',
    qts = 'qts'
}

export const quantityTypeOptions: FormOption[] = Object.values(Quantity).map(val => ({ 'id': val, 'label': val }));
