import { useAsyncList } from "@react-stately/data";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useLoading, useMessage, useUserProfile } from "../App";
import { useSearchFilter } from "./Table";

export function useDataSource(table: string, zone: boolean) {
    const { setIsLoading } = useLoading();
    const message = useMessage();
    const { userProfile } = useUserProfile();
    const { settings } = userProfile ?? {};
    const filterZone = settings?.zone ?? null;

    const auth = useAuth();
    const { access_token } = auth.user ?? {};
    const { filterSearch } = useSearchFilter();

    const getFilterText = React.useCallback(() => {
        let filterText = '';
        let uriComponents = [];
        if (filterSearch) uriComponents.push('filter=' + encodeURIComponent(filterSearch));
        if (filterZone && zone) uriComponents.push('zone=' + encodeURIComponent(filterZone));
        if (uriComponents.length > 0) filterText += '?' + uriComponents.join('&');
        return filterText;
    }, [filterSearch, filterZone, zone]);

    const onLoadList = React.useCallback(async (signal: any, filterText: any) => {
        setIsLoading && setIsLoading(true);
        let url = process.env.REACT_APP_API + '/' + table + filterText;
        let res = await fetch(url, {
            signal,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${access_token}`,
            },
        });

        let json = await res.json();
        if (filterText === getFilterText()) {
            // If filter changed, we gonna be called again
            setIsLoading && setIsLoading(false);
        }

        if (!res.ok) {
            message('danger', json.message);
        }

        return {
            items: res.ok ? json.results : [],
        };
    }, [access_token, getFilterText, message, setIsLoading, table])

    const list = useAsyncList({
        async load({ signal, filterText }) { return await onLoadList(signal, filterText); },
        async sort({ items, sortDescriptor }: any) {
            return {
                items: items.sort((a: any, b: any) => {
                    let first = a[sortDescriptor.column];
                    let second = b[sortDescriptor.column];
                    let cmp = (parseInt(first) || first) < (parseInt(second) || second) ? -1 : 1;

                    if (sortDescriptor.direction === "desc") {
                        cmp *= -1;
                    }

                    return cmp;
                }),
            };
        },
    });

    React.useEffect(() => {
        const filterText = getFilterText();
        if (list.filterText !== filterText) list.setFilterText(filterText);
    }, [getFilterText, list]);

    return list;
}
