import * as React from "react";
import Table from "../data/Table";
import { text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { useDataSource } from "../data/Source";
import { FormOption } from "../data/Types";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.type ? row.type.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.ata}
            </Typography>
            <Typography level="body-xs">
                {row.type}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'ata',
    table: 'ata',
    single: 'ata',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'ATA', name: 'ata' },
        { title: 'Type', name: 'type' },
    ]
}
const fields = [
    text('ATA', 'ata', true),
    text('Type', 'type', true),
];

export function useATAOptions() {
    const { table, primary, zone } = metadata;
    const [options, setOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setOptions(listData.items.map((item: any) => ({ 'id': item[primary], 'label': `${item[primary]}: ${item['type']}` })))
        }
    }, [listData.isLoading, listData.items, primary, setOptions])

    return options;
}

export default function ATA() {
    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}