import React from "react";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BookIcon from '@mui/icons-material/Book';
import HandymanIcon from '@mui/icons-material/Handyman';
// import BugReportIcon from '@mui/icons-material/BugReport';
import AirlinesIcon from '@mui/icons-material/Airlines';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import MapIcon from '@mui/icons-material/Map';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GridOnIcon from '@mui/icons-material/GridOn';
import HomeIcon from "@mui/icons-material/Home";
import Airports from "../views/Airports";
import Customers from "../views/Customers";
import Couriers from "../views/Couriers";
import Manufacturers from "../views/Manufacturers";
import PartNumbers from "../views/PartNumbers";
import Locations from "../views/Locations";
import Countries from "../views/Countries";
import Currency from "../views/Currency";
import Store from "../views/Store";
import Main from "../Main";
import Tools from "../views/Tools";
import Zones from "../views/Zones";
import Handovers from "../views/Handovers";
import { Groups, SupervisedUserCircle } from "@mui/icons-material";
import Owners from "../views/Owners";
import Users from "../views/Users";

export type MenuItem = {
    title: string,
    icon: React.ReactElement,
    element: React.ReactElement,
    path: string,
}

export const apps: Array<[string, Array<MenuItem>]> = [
    ["Main", [
        {
            title: "Overview",
            icon: <HomeIcon />,
            element: <Main />,
            path: "/"
        },
    ]],
    ["Part 145", [
        /*{
            title: "Maintenance",
            icon: <EngineeringIcon />,
            element: <Airports />,
            path: "/maintenance"
        },
       {
            title: "Defects",
            icon: <BugReportIcon />,
            element: <Airports />,
            path: "/defects"
        },
        */
        {
            title: "Store",
            icon: <WarehouseIcon />,
            element: <Store />,
            path: "/store"
        },
        {
            title: "Tools",
            icon: <HandymanIcon />,
            element: <Tools />,
            path: "/tools"
        },
        {
            title: "Handover",
            icon: <BookIcon />,
            element: <Handovers />,
            path: "/handovers"
        },
        {
            title: "Locations",
            icon: <CorporateFareIcon />,
            element: <Locations />,
            path: "/locations"
        },
        {
            title: "Manufacturers",
            icon: <FactoryIcon />,
            element: <Manufacturers />,
            path: "/manufacturers"
        },
        {
            title: "Part Numbers",
            icon: <PrecisionManufacturingIcon />,
            element: <PartNumbers />,
            path: "/partnumbers"
        },
        {
            title: "Zones",
            icon: <GridOnIcon />,
            element: <Zones />,
            path: "/zones"
        },
        {
            title: "Couriers",
            icon: <LocalShippingIcon />,
            element: <Couriers />,
            path: "/couriers"
        },
        {
            title: "Owners",
            icon: <AirlinesIcon />,
            element: <Owners />,
            path: "/owners"
        },
    ]],

    ["Admin", [{
        title: "Users",
        icon: <SupervisedUserCircle />,
        element: <Users />,
        path: "/users"
    },
    {
        title: "Customers",
        icon: <Groups />,
        element: <Customers />,
        path: "/customers"
    },
    {
        title: "Airports",
        icon: <FlightTakeoffIcon />,
        element: <Airports />,
        path: "/airports"
    },
    {
        title: "Countries",
        icon: <MapIcon />,
        element: <Countries />,
        path: "/countries"
    },
    {
        title: "Currencies",
        icon: <AttachMoneyIcon />,
        element: <Currency />,
        path: "/currency"
    },
    ]]];
