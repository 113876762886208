import * as React from "react";
import Table from "../data/Table";
import { text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.company ? row.company.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.account}
            </Typography>
            <Typography level="body-xs">
                {row.company}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'id',
    table: 'couriers',
    single: 'courier',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'Account', name: 'account' },
        { title: 'Company', name: 'company' },
    ]
}

const fields = [
    text('Account', 'account', true),
    text('Company', 'company', true)
];

export default function Couriers() {
    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>
}