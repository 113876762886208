import * as React from "react";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import Main from "./Main"

import { useAuth, hasAuthParams } from "react-oidc-context";
import Scanner from "./views/Scanner";
import { Alert, Box, CircularProgress, CssBaseline, CssVarsProvider, IconButton } from "@mui/joy";
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import Header from "./navigation/Header";
import Sidebar from "./navigation/Sidebar";
import Breadcrumb from "./navigation/Breadcrumb";
import { apps } from "./navigation/Menu";
import { RecoilRoot } from "recoil";
import { jwtDecode } from 'jwt-decode';
import QRCode from "./util/QRCode";
import { LoadingData, UserProfile, UserProfileData, UserSettings } from "./data/Types";
import { select, submit } from "./data/Utils";
import { IdTokenClaims } from "oidc-client-ts";

type UserToken = {
  resource_access: {
    'mavsys-app': {
      roles: [string]
    }
  },
  customer: string
}

type Message = {
  color: string,
  text: string,
}

export const MessageContext = React.createContext((color: string, text: string) => { });

export function useMessage() {
  return React.useContext(MessageContext);
}

type UserAccess = {
  admin: boolean,
  viewer: boolean,
  company: string,
}

const defaultUserAccess = {
  admin: false,
  viewer: false,
  company: '',
};

export const UserAccessContext = React.createContext<UserAccess>(defaultUserAccess);
export function useUserAccess() {
  return React.useContext(UserAccessContext);
}

export const LoadingContext = React.createContext<LoadingData>({
  isLoading: false,
  setIsLoading: () => { },
});
export const useLoading = () => React.useContext(LoadingContext);

export const UserProfileContext = React.createContext<UserProfileData>({
  userProfile: null,
  setUserSettings: () => { },
});
export const useUserProfile = () => React.useContext(UserProfileContext);

interface ExtendedAuthProfile extends IdTokenClaims {
  authcode?: string
}

export default function App() {
  const auth = useAuth();
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth, auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  const [messages, setMessages] = React.useState<Array<Message>>([]);
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => { mounted.current = false; };
  }, []);

  const message = React.useCallback((color: string, text: string) => {
    const msg = { color, text };
    setMessages((msgs) => [...msgs, msg]);
    setTimeout(() => {
      if (mounted.current) {
        setMessages((msgs) => msgs.filter(idx => idx !== msg));
      }
    }, 2000)
  }, [])

  const routes = apps.reduce(([_, prev], [__, next]) => (['', [...prev, ...next]]))[1];

  const userAccess = React.useMemo(() => {
    if (auth.isLoading || auth.user?.access_token == null) return defaultUserAccess;
    const token = jwtDecode<UserToken>(auth.user?.access_token);

    return {
      admin: (token.resource_access['mavsys-app']?.roles ?? []).includes('admin'),
      viewer: (token.resource_access['mavsys-app']?.roles ?? []).includes('viewer'),
      company: token.customer
    }
  }, [auth]);

  const profile: ExtendedAuthProfile | undefined = auth.user?.profile;
  const { authcode } = profile ?? {};

  const [userProfile, setUserProfile] = React.useState<UserProfile | null>(null);
  const setUserSettings = React.useCallback((settings: UserSettings | null) => {
    if (!authcode || !userProfile) return;
    submit(auth.user?.access_token, JSON.stringify(settings), 'settings').then(() => {
      setUserProfile((profile: UserProfile | null) =>
        profile ? ({ ...profile ?? {}, settings }) : null);
    }).catch(err => {
      message('danger', 'Error saving settings: ' + err)
    })
  }, [auth.user?.access_token, authcode, message, userProfile])

  const userProfileContext = React.useMemo(() => ({
    userProfile, setUserSettings
  }), [setUserSettings, userProfile]);

  React.useEffect(() => {
    if (!authcode || userProfile?.authorisation === authcode) return;
    select(auth.user?.access_token, 'users', authcode).then(data => {
      setUserProfile(data.settings ? { ...data, settings: JSON.parse(data.settings) } : data);
    }).catch(err => {
      setUserProfile({
        email: auth.user?.profile.email ?? "",
        authorisation: authcode,
        customer: userAccess.company,
        name: `${auth.user?.profile.given_name} ${auth.user?.profile.family_name}`,
        settings: null
      });
      message('warning', 'No settings found for user')
    })
  }, [auth.user, authcode, message, setUserSettings, userAccess.company, userProfile])

  if (pathname.startsWith('/qr/')) {
    return <Routes>
      <Route path="/qr/:area/:code" element={<QRCode />} />
    </Routes>
  }

  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      {(auth.isLoading || !userProfile) && <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <CircularProgress
          sx={{
            "margin": "auto",
            "--CircularProgress-size": { xs: "70dvw", md: "360px" }
          }}
        />
      </Box>}
      {!auth.isLoading && auth.isAuthenticated && userProfile && <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <UserAccessContext.Provider value={userAccess}>
          <MessageContext.Provider value={message}>
            <UserProfileContext.Provider value={userProfileContext}>
              <Header />
              <Sidebar />
              <Box
                component="main"
                sx={{
                  px: { xs: 2, md: 6 },
                  pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                  },
                  pb: { xs: 2, sm: 2, md: 3 },
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: 0,
                  height: '100dvh',
                  gap: 1,
                }}
              >
                <Breadcrumb />
                {messages.map((msg: any, index) => <Alert
                  key={index}
                  startDecorator={msg.color === 'danger' ? <WarningIcon /> : <InfoIcon />}
                  variant="solid"
                  color={msg.color}
                  endDecorator={<React.Fragment>
                    <IconButton variant="solid" size="sm" color={msg.color}>
                      <CloseIcon />
                    </IconButton>
                  </React.Fragment>}
                >
                  {msg.text}
                </Alert>
                )}
                <RecoilRoot>
                  <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/scan" element={<Scanner />} />
                    {routes.map(route => <Route key={route.path} path={route.path} element={route.element} />)}
                    {routes.map(route => <Route key={route.path + '/value'} path={route.path + '/:uuid'} element={route.element} />)}
                  </Routes>
                </RecoilRoot>
              </Box>
            </UserProfileContext.Provider>
          </MessageContext.Provider>
        </UserAccessContext.Provider>
      </Box>}
    </CssVarsProvider>
  );
}
