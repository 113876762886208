import React from "react";
import { FormOption } from "./Types";
import { Typography } from "@mui/joy";

export function useDateRenderer() {
    return React.useCallback((value: string) => <Typography level="body-xs">
        {new Date(value).toLocaleString()}
    </Typography>, []);
}

export function useMapper(options: FormOption[] | null): { [k: string]: string } {
    return React.useMemo(() =>
        Object.fromEntries(options?.map(fo => [fo.id, fo.label ?? 'Unknown']) ?? [])
        , [options]);
}

export async function submit(token: string | undefined, body: string, table: string, primary: string | null = null) {
    let target = table;
    if (primary) {
        target += '/' + encodeURIComponent(primary);
    }
    const res = await fetch(process.env.REACT_APP_API + '/' + target, {
        method: primary ? 'PUT' : 'POST',
        body,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    })
    const data = await res.json();
    if (!res.ok) throw Error(data?.message || data?.error || data);
}

export async function select(token: string | undefined, table: string, primary: string) {
    let target = table + '/' + encodeURIComponent(primary);
    const res = await fetch(process.env.REACT_APP_API + '/' + target, {
        method: 'GET',
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    })
    const data = await res.json();
    if (!res.ok) throw Error(data?.message || data?.error || data);
    return data;
}