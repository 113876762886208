import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FieldContext, MetaContext } from "../store/Context";
import { select, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FormOption } from "../data/Types";
import { useAirportOptions } from "./Airports";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.zone ? row.zone.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.zone}
            </Typography>
            <Typography level="body-xs">
                {row.description} &bull; {row.airport_name}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'id',
    table: 'zones',
    single: 'zone',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'Zone', name: 'zone' },
        { title: 'Description', name: 'description' },
        { title: 'Airport', name: 'airport_name' },
    ]
}

export function useZoneOptions() {
    const { table, primary, zone } = metadata;
    const [options, setOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setOptions(listData.items.map((item: any) => ({ 'id': item['zone'], 'label': item['description'] })))
        }
    }, [listData.isLoading, listData.items, primary, setOptions])

    return options;
}

export default function Zones() {
    const airportOptions = useAirportOptions();
    const fields = React.useMemo(() => [
        text('Zone', 'zone', true),
        text('Description', 'description', true),
        select('Airport', 'airport', 'airport_name', airportOptions, true),
    ], [airportOptions]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}