import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FormOption } from "../data/Types";
import { text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
  return row.name ? row.name.charAt(0) : '?';
}

function renderItem(row: any) {
  return (
      <div>
          <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
              {row.name}
          </Typography>
          <Typography level="body-xs">
              {row.domain}
          </Typography>
      </div>
  );
}

const metadata = {
  primary: 'customer',
  table: 'customers',
  single: 'customer',
  zone: false,
  renderDecorator,
  renderItem,
  headers: [
    { title: 'Name', name: 'name' },
    { title: 'Domain', name: 'domain' }
  ]
}
const fields = [
  text('Customer', 'customer', true),
  text('Name', 'name', true),
  text('Domain', 'domain', true)
];

export function useCustomerOptions() {
  const { table, primary, zone } = metadata;
  const [customerOptions, setOptions] = React.useState<FormOption[] | null>(null);
  const listData = useDataSource(table, zone);
  React.useEffect(() => {
    if (!listData.isLoading) {
      setOptions(listData.items.map((item: any) => ({ 'id': item[primary], 'label': item['name'] })))
    }
  }, [listData.isLoading, listData.items, primary, setOptions])
  return customerOptions;
}

export default function Customers() {
  return <MetaContext.Provider value={metadata}>
    <FieldContext.Provider value={fields}>
      <Table form={<StandardForm />} />
    </FieldContext.Provider>
  </MetaContext.Provider>;
}