import * as React from "react";
import Table from "../data/Table";
import { StandardForm } from "../data/Editor";
import { date, select, sign, text, uuid, view } from "../data/fields/Fields";
import { usePartNumberOptions } from "./PartNumbers";
import { useLocationOptions } from "./Locations";
import { MetaContext, FieldContext } from "../store/Context";
import { FormOption, Status, statusOptions } from "../data/Types";
import type { MetaContextType } from "../store/Context"
import { useOwnerOptions } from "./Owners";
import { useAuth } from "react-oidc-context";
import { useLoading, useMessage } from "../App";
import { submit } from "../data/Utils";
import { Typography, Chip, ColorPaletteProp } from "@mui/joy";
import { AutorenewRounded, Block, CheckRounded, MoreHorizRounded, QuestionMark } from "@mui/icons-material";

export enum Type {
    Tool = 'Tool',
    CalibratedTool = 'Calibrated Tool',
    GroundSupportEquipment = 'Ground Support Equipment',
}

export const typeOptions: FormOption[] = Object.values(Type).map(val => ({ 'id': val, 'label': val }));

export type ToolItem = {
    id: number,
    description: string,
    partnumber: string, // Key
    serialnumber: string,
    location: string, // Key
    type: Type,
    status: Status,
    price: number,
    currency: string, // Key
    expiry: string,
    certificate: boolean,
}

const borrowTitle = (row: any): string => row.borrowed_by ? "Return Tool" : "Borrow Tool";

const table = 'tools';
const primary = 'id';

function useBorrowAction(): (row: any, reload: any) => void {
    const auth = useAuth();
    const { access_token, profile } = auth.user ?? {};
    const message = useMessage();
    const { setIsLoading } = useLoading();

    return React.useCallback((row: any, reload: any) => {
        if (!row[primary]) {
            return;
        }
        if (!profile?.authcode) {
            message('danger', 'No authcode set in profile');
        }
        setIsLoading(true);
        const body = JSON.stringify({ borrowed_by: row.borrowed_by ? null : profile?.authcode });
        submit(access_token, body, table, row[primary])
            .then(() => {
                message('success', (row.borrowed_by ? 'Returned' : 'Borrowed') + ' Tool OK');
                reload && reload();
            })
            .catch((e) => {
                message('danger', e);
                setIsLoading(false);
            })
    }, [access_token, message, profile?.authcode, setIsLoading])
}

function renderDecorator(row: any) {
    return row.type ? row.type.charAt(0) : '?';
}

function renderItem(row: any) {
    const status: string = row.status ? row.status : 'Unverified';
    return <div>
        {row.borrowed_by && <Chip
            sx={{ float: 'right' }}
            variant="soft"
            size="sm"
            color="primary">{row.borrowed_by_name}</Chip>}
        <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
            {row.description}
        </Typography>
        <Chip
            sx={{ float: 'right' }}
            variant="soft"
            size="sm"
            startDecorator={
                {
                    Unverified: <QuestionMark />,
                    Serviceable: <CheckRounded />,
                    Unserviceable: <AutorenewRounded />,
                    Unsalvageable: <Block />,
                    Hold: <MoreHorizRounded />,
                }[status]
            }
            color={
                {
                    Unverified: 'neutral',
                    Serviceable: 'success',
                    Unserviceable: 'warning',
                    Unsalvageable: 'danger',
                    Hold: 'primary',
                }[status] as ColorPaletteProp
            }
        >
            {status}
        </Chip>
        <Typography level="body-xs">
            {row.partnumber_name} &bull; {row.serialnumber} &bull; {row.location_name}
        </Typography>
        <Typography level="body-xs">
            {row.owner_name} &bull; {row.expiry}
        </Typography>
    </div>;
}

export default function Tools() {
    const partNumberOptions = usePartNumberOptions();
    const locationOptions = useLocationOptions();
    const ownerOptions = useOwnerOptions();
    const borrowAction = useBorrowAction();

    const fields = React.useMemo(() => [
        view('Description', 'description'),
        text('Serial Number', 'serialnumber', true),
        select('Part Number', 'partnumber', 'partnumber_name', partNumberOptions, true),
        select('Location', 'location', 'location_name', locationOptions, true),
        select('Type', 'type', 'type', typeOptions, true), // TODO
        select('Status', 'status', 'status', statusOptions, true), // TODO
        select('Owner', 'owner', 'owner_name', ownerOptions, true),
        date('Expiry', 'expiry'),
        text('Remarks', 'remarks'),
        text('Company ID', 'company_id'),
        uuid('QR Code', 'uuid'),
        sign('Borrowed By', ['borrowed_by', 'borrowed_by_name', 'borrowed_by_auth']),
    ], [locationOptions, ownerOptions, partNumberOptions]);

    const metadata: MetaContextType = React.useMemo(() => ({
        table,
        primary,
        single: 'tool',
        zone: true,
        renderDecorator,
        renderItem,
        headers: [
            { title: 'Description', name: 'description' },
            { title: 'Serial Number', name: 'serialnumber' },
            { title: 'Part Number', name: 'partnumber_name' },
            { title: 'Location', name: 'location_name' },
            { title: 'Type', name: 'type' },
            { title: 'Status', name: 'status' },
            { title: 'Borrowed By', name: 'borrowed_by_name' },
            { title: 'Owner', name: 'owner_name' },
            { title: 'Expiry', name: 'expiry' }
        ],
        uploads: [
            { title: 'Certificate', name: 'certificate' }
        ],
        actions: [
            { title: () => 'Show QR Code', action: (row) => window.open('/qr/tools/' + row.uuid, '_blank') },
            { title: borrowTitle, action: borrowAction, showInForm: true }
        ],
    }), [borrowAction]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}