import * as React from "react";
import Table from "../data/Table";
import { useDataSource } from "../data/Source";
import { FieldContext, MetaContext } from "../store/Context";
import { select, text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FormOption } from "../data/Types";
import { useCountryOptions } from "./Countries";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.icao ? row.icao.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.icao}
            </Typography>
            <Typography level="body-xs">
                {row.iata} &bull; {row.country_name} &bull; {row.name}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'icao',
    table: 'airports',
    single: 'airport',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'ICAO', name: 'icao' },
        { title: 'IATA', name: 'iata' },
        { title: 'Country', name: 'country_name' },
        { title: 'Name', name: 'name' }
    ]
}

export function useAirportOptions() {
    const { table, primary, zone } = metadata;
    const [airportOptions, setAirportOptions] = React.useState<FormOption[] | null>(null);
    const listData = useDataSource(table, zone);
    React.useEffect(() => {
        if (!listData.isLoading) {
            setAirportOptions(listData.items.map((item: any) => ({ 'id': item[primary], 'label': item['name'] })))
        }
    }, [listData.isLoading, listData.items, primary, setAirportOptions])

    return airportOptions;
}

export default function Airports() {
    const countryOptions = useCountryOptions();
    const fields = React.useMemo(() => [
        text('ICAO', 'icao', true),
        text('IATA', 'iata', true),
        select('Country', 'country', 'country_name', countryOptions, true),
        text('Name', 'name', true)
    ], [countryOptions]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}