import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { LoadingContext, useLoading, useMessage } from '../App';
import { useAuth } from 'react-oidc-context';
import { EditType, FormData, FormType, Header, SearchData, SortDescriptor, TableData } from "../data/Types";
import { Avatar, CircularProgress, List, ListDivider, ListItem, ListItemContent, ListItemDecorator } from '@mui/joy';
import { useDataSource } from './Source';
import { useMetadata } from '../store/Context';
import { useHandleFileDownload, useHandleFileDelete, useHandleFileUpload } from './fields/FileHandler';
import { useNavigate, useParams } from 'react-router-dom';

type DataTableProps = {
    form: React.ReactElement | null,
}

export const TableDataContext = React.createContext<TableData>({
    rows: [],
    reload: () => { }
});
export const useTableData = () => React.useContext(TableDataContext);

export const FormDataContext = React.createContext<FormData>({
    formData: null,
    setFormData: () => { }
});
export const useFormData = () => React.useContext(FormDataContext);

export const FilterSearchContext = React.createContext<SearchData>({
    filterSearch: '',
    setFilterSearch: () => { },
});
export const useSearchFilter = () => React.useContext(FilterSearchContext);

export const CloseFormContext = React.createContext<(arg0: boolean) => void>(() => { });
export const useCloseForm = () => React.useContext(CloseFormContext);

export const standardMenuButtoon = <MenuButton
    slots={{ root: IconButton }}
    slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
>
    <MoreHorizRoundedIcon />
</MenuButton>

export const listMenuButton = <MenuButton
    slots={{ root: IconButton }}
    slotProps={{ root: { style: { display: 'block' }, variant: 'plain', color: 'neutral', size: 'sm' } }}
>
    <MoreHorizRoundedIcon />
</MenuButton >

export function useDropdown(menuButton: React.ReactNode) {
    const { table, primary, uploads, actions } = useMetadata()
    const downloadFile = useHandleFileDownload(table, primary);
    const uploadFile = useHandleFileUpload();
    const deleteFile = useHandleFileDelete();
    const auth = useAuth();
    const message = useMessage();
    const { setFormData } = useFormData();
    const { reload } = useTableData();

    const deleteItem = React.useCallback((item: any) => {
        async function doSubmit() {
            const token = auth.user?.access_token;
            const res = await fetch(process.env.REACT_APP_API + '/' + table + '/' + item[primary], {
                method: 'DELETE',
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            const data = await res.json();
            if (!res.ok) {
                message('danger', data.message);
            } else {
                message('success', 'Deleted OK');
                reload();
            }
        }

        doSubmit().catch(() => { });
    }, [auth.user?.access_token, message, table, reload, primary])

    return React.useCallback((row: any) => <Dropdown>
        {menuButton}
        <Menu size="sm" sx={{ minWidth: 140 }}>
            {actions && actions.map(u =>
                <MenuItem key={u.title(row)}>
                    <Button onClick={() => u.action(row, reload)}
                        variant="plain"
                        color="neutral">
                        {u.title(row)}
                    </Button>
                </MenuItem>
            )}
            {actions && <Divider />}
            {uploads && uploads.map(u =>
                <React.Fragment key={u.name}>
                    <MenuItem key={u.name + '-upload'}>
                        <Button onClick={() => uploadFile(u, row)}
                            variant="plain"
                            color="neutral">
                            Upload {u.title}
                        </Button>
                    </MenuItem>
                    {row[u.name] === 1 && <MenuItem key={u.name + 'download'}>
                        <Button onClick={() => downloadFile(u, row)}
                            variant="plain"
                            color="neutral">
                            Download {u.title}
                        </Button>
                    </MenuItem>}
                </React.Fragment>
            )}
            {uploads && uploads.filter(u => row[u.name]).map(u =>
                <MenuItem key={u.name}>
                    <Button onClick={() => deleteFile(u, row)}
                        variant="plain"
                        color="danger">
                        Delete {u.title}
                    </Button>
                </MenuItem>
            )}
            {uploads && <Divider />}
            <MenuItem><Button variant="plain" color="neutral" onClick={() => {
                setFormData({ type: EditType.SHOW, row });
            }}>Show</Button></MenuItem>
            <MenuItem><Button variant="plain" color="neutral" onClick={() => {
                setFormData({ type: EditType.EDIT, row });
            }}>Edit</Button></MenuItem>
            <MenuItem>
                <Button variant="plain" color="danger" onClick={() => deleteItem(row)}>
                    Delete
                </Button>
            </MenuItem>
        </Menu>
    </Dropdown>, [actions, deleteFile, deleteItem, downloadFile, menuButton, reload, setFormData, uploadFile, uploads])

}

function ListTable() {
    const { rows } = useTableData();
    const { primary, renderItem, renderDecorator } = useMetadata();
    const dropdown = useDropdown(listMenuButton);
    const { setFormData, formData } = useFormData();

    return renderItem ? <Box sx={{ display: { xs: formData ? 'none' : 'block', sm: 'none' } }}>
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            {rows.map<React.ReactNode>((row: any, index: number) => (
                <React.Fragment key={row[primary]}>
                    <ListItem
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        }}
                    >
                        <ListItemDecorator sx={{ display: 'block' }}>
                            {renderDecorator && <Avatar onClick={() => {
                                setFormData({ type: EditType.SHOW, row });
                            }} size="sm">{renderDecorator(row)}</Avatar>}
                            {dropdown(row)}
                        </ListItemDecorator>
                        <ListItemContent
                            sx={{ paddingLeft: '5px' }}
                            onClick={() => {
                                setFormData({ type: EditType.SHOW, row });
                            }}>
                            {renderItem(row)}
                        </ListItemContent>
                    </ListItem>
                    {index < rows.length - 1 && <ListDivider />}
                </React.Fragment>
            ))}
        </List>
    </Box> : null
}

export function DataTable({ form }: DataTableProps) {
    const [sorts, setSorts] = React.useState<Array<SortDescriptor>>([]);
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const dropdown = useDropdown(standardMenuButtoon);
    const { isLoading } = useLoading();
    const { formData, setFormData } = useFormData();
    const { rows } = useTableData();
    const { headers, primary, single, renderItem } = useMetadata();
    const { filterSearch, setFilterSearch } = useSearchFilter();

    const newItem = React.useCallback(() => {
        setFormData({ type: EditType.NEW });
    }, [setFormData]);

    return (
        <React.Fragment>
            {formData != null && form}
            <Sheet
                sx={{
                    display: { xs: formData ? 'none' : 'flex', sm: 'none' },
                    my: 1,
                    gap: 1,
                }}
            >
                <Input
                    size="sm"
                    placeholder="Search"
                    startDecorator={<SearchIcon />}
                    sx={{ flexGrow: 1 }}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={newItem}
                >
                    <AddIcon />
                </IconButton>
            </Sheet>
            <Box
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: formData ? 'none' : 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                }}
            >
                <FormControl sx={{ flex: 1 }} size="sm">
                    <Input size="sm"
                        placeholder={"Search for " + single}
                        value={filterSearch}
                        onChange={(event) => setFilterSearch(event.target.value)}
                        startDecorator={<SearchIcon />}
                        endDecorator={<ClearIcon onClick={() => setFilterSearch("")} />}
                    />
                </FormControl>
                <FormControl size="sm">
                    <Button
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={newItem}
                    >
                        Add
                    </Button>
                </FormControl>
            </Box>
            {isLoading && <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="50vh">
                <CircularProgress sx={{
                    "margin": "auto",
                    "--CircularProgress-size": { xs: "70dvw", md: "360px" }
                }} />
            </Box>}
            {!isLoading && <Sheet
                variant="outlined"
                sx={{
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                    visibility: { xs: formData ? 'hidden' : 'visible', sm: formData ? 'hidden' : 'visible' }
                }}
            >
                {rows.length === 0 && <Typography sx={{ textAlign: 'center', margin: '5px' }}>No rows found</Typography>}
                {rows.length > 0 && <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                        display: { xs: renderItem || formData ? 'none' : 'table', sm: formData ? 'none' : 'table' }
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                                <Checkbox
                                    size="sm"
                                    indeterminate={
                                        selected.length > 0 && selected.length !== rows.length
                                    }
                                    checked={selected.length === rows.length}
                                    onChange={(event) => {
                                        setSelected(
                                            event.target.checked ? rows.map((row: any) => row[primary]) : [],
                                        );
                                    }}
                                    color={
                                        selected.length > 0 || selected.length === rows.length
                                            ? 'primary'
                                            : undefined
                                    }
                                    sx={{ verticalAlign: 'text-bottom' }}
                                />
                            </th>
                            {headers.map((header: Header) => {
                                const order = sorts.find(sort => sort.column === header.name);
                                const others = sorts.filter(sort => sort.column !== header.name) ?? [];
                                return <th key={header.name} style={{
                                    width: header.width ?? 120, padding: '12px 6px'
                                }}>
                                    <Link
                                        underline="none"
                                        color="primary"
                                        component="button"
                                        onClick={() => setSorts(
                                            sorts => order == null ?
                                                [{ column: header.name, direction: 'asc' }, ...others]
                                                : order.direction === 'asc' ?
                                                    [{ column: header.name, direction: 'asc' }, ...others]
                                                    : [...others]
                                        )}
                                        fontWeight="lg"
                                        endDecorator={order ? <ArrowDropDownIcon /> : null}
                                        sx={{
                                            '& svg': {
                                                transition: '0.2s',
                                                transform:
                                                    order?.direction === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                            },
                                        }}
                                    >
                                        {header.title}
                                    </Link>
                                </th>
                            })}
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row: any) => (
                            <tr key={row[primary]}>
                                <td style={{ textAlign: 'center', width: 120 }}>
                                    <Checkbox
                                        size="sm"
                                        checked={selected.includes(row[primary])}
                                        color={selected.includes(row[primary]) ? 'primary' : undefined}
                                        onChange={(event) => {
                                            setSelected((ids) =>
                                                event.target.checked
                                                    ? ids.concat(row[primary])
                                                    : ids.filter((itemId) => itemId !== row.id),
                                            );
                                        }}
                                        slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                                        sx={{ verticalAlign: 'text-bottom' }}
                                    />
                                </td>
                                {headers.map((header: Header) => <td key={header.name}>
                                    {header.render ? header.render(row[header.name], row) :
                                        <Typography level="body-xs">
                                            {header.mapper ? header.mapper[row[header.name]] : row[header.name]}
                                        </Typography>}
                                </td>)}
                                <td style={{ textAlign: 'center', width: 120 }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        {dropdown(row)}
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>}
                {rows.length > 0 && <ListTable />}
            </Sheet>}
        </React.Fragment>
    );
}

export function DataTableSource({ form }: DataTableProps) {
    const { table, zone } = useMetadata();
    const list = useDataSource(table, zone);
    const [formData, setFormData] = React.useState<FormType | null>(null);
    const message = useMessage();
    const navigate = useNavigate();
    const { uuid } = useParams();

    const tableContext = React.useMemo(() => ({
        reload: list.reload,
        rows: list.items,
    }), [list.items, list.reload]);

    const formContext = React.useMemo(() => ({
        formData, setFormData
    }), [formData]);

    const closeForm = React.useCallback((doReload: boolean) => {
        if (uuid) {
            navigate(`/${table}`);
        }
        setFormData(null);
        doReload && list.reload();
    }, [list, navigate, table, uuid])

    React.useEffect(() => {
        if (!uuid || list.items.length === 0) return;
        const [row] = list.items.filter((row: any) => row.uuid === uuid);
        if (!row) {
            console.error('Item not found from QR code')
            navigate(`/${table}`);
            message('warning', 'Item not found from QR code');
            return;
        }
        if (formData?.row?.uuid === uuid) {
            return;
        }
        setFormData({ type: EditType.SHOW, row })
    }, [formData?.row?.uuid, list.items, message, navigate, table, uuid])

    return <TableDataContext.Provider value={tableContext}>
        <FormDataContext.Provider value={formContext}>
            <CloseFormContext.Provider value={closeForm}>
                <DataTable form={form} />
            </CloseFormContext.Provider>
        </FormDataContext.Provider>
    </TableDataContext.Provider >;
}


export default function Data({ form }: DataTableProps) {
    const [filterSearch, setFilterSearch] = React.useState<string>('');

    const filterSearchContext = React.useMemo(() => ({
        filterSearch, setFilterSearch
    }), [filterSearch]);

    const [isLoading, setIsLoading] = React.useState(true);
    const loadingContext = React.useMemo(() => ({
        isLoading, setIsLoading,
    }), [isLoading]);

    return <LoadingContext.Provider value={loadingContext}>
        <FilterSearchContext.Provider value={filterSearchContext}>
            <DataTableSource form={form} />
        </FilterSearchContext.Provider >
    </LoadingContext.Provider >;
}