import { Action, Field, Header } from "../data/Types";
import React from "react";

// Meta Data Context

export type MetaContextType = {
    primary: string,
    table: string,
    single: string,
    zone: boolean,
    headers: Header[],
    uploads?: Header[],
    actions?: Action[],
    renderItem?: (row: any) => React.ReactNode,
    renderDecorator?: (row: any) => React.ReactNode
}

export const MetaContext = React.createContext<MetaContextType>({
    primary: '', table: '', single: '', zone: false, headers: []
});

export function useMetadata() {
    return React.useContext(MetaContext);
}

// Field Context

export const FieldContext = React.createContext<Field[]>([]);

export function useFields() {
    return React.useContext(FieldContext);
}