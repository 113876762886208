import * as React from "react";
import Table from "../data/Table";
import { StandardForm } from "../data/Editor";
import { date, number, select, text, uuid } from "../data/fields/Fields";
import { usePartNumberOptions } from "./PartNumbers";
import { useLocationOptions } from "./Locations";
import { MetaContext, FieldContext } from "../store/Context";
import { Quantity, Status, statusOptions } from "../data/Types";
import type { MetaContextType } from "../store/Context"
import { Link, Typography, Chip, ColorPaletteProp } from "@mui/joy";
import { useHandleFileDownload } from "../data/fields/FileHandler";
import { useOwnerOptions } from "./Owners";
import { CheckRounded, QuestionMark } from "@mui/icons-material";

export type StoreItem = {
    id: number,
    description: string,
    partnumber: string, // Key
    serialnumber: string,
    location: string, // Key
    status: Status,
    quantity: number,
    original_quantity: number,
    quantity_type: Quantity,
    price: number,
    currency: string, // Key
    expiry: string,
    form: boolean,
}

function renderDecorator(row: any) {
    return row.description ? row.description.charAt(0) : '?';
}

function renderItem(row: any, download: (args: any, row: any) => void) {
    const hasForm = row.status === 'Serviceable' && row.form;
    const chipContent = hasForm ? (
        <Link onClick={() => download({ title: 'Form 1', name: 'form' }, row)} sx={{ textDecoration: 'none', color: 'inherit' }}>
            Serviceable
        </Link>
    ) : (
        "(NO FORM)"
    );
    const chipDecorator = hasForm ? <CheckRounded /> : <QuestionMark />;
    const chipColor: ColorPaletteProp = hasForm ? 'success' : 'neutral';

    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.description}
            </Typography>
            <Chip
                sx={{ float: 'right' }}
                variant="soft"
                size="sm"
                startDecorator={chipDecorator}
                color={chipColor}
            >
                {chipContent}
            </Chip>
            <Typography level="body-xs">
                {row.serialnumber} &bull; {row.partnumber_name} &bull; {row.location_name} &bull;
            </Typography>
            <Typography level="body-xs">
                {row.quantity} / {row.original_quantity} {row.quantity_type} &bull; {row.owner_name} &bull; {row.expiry}
            </Typography>
        </div>
    );
}


export default function Store() {
    const partNumberOptions = usePartNumberOptions();
    const locationOptions = useLocationOptions();
    const ownerOptions = useOwnerOptions();
    const download = useHandleFileDownload('store', 'id');

    const fields = React.useMemo(() => [
        text('Description', 'description'),
        text('Serial Number', 'serialnumber', true),
        select('Part Number', 'partnumber', 'partnumber_name', partNumberOptions, true),
        select('Location', 'location', 'location_name', locationOptions, true),
        select('Status', 'status', 'status', statusOptions, true), // TODO
        number('Quantity', 'quantity'),
        select('Owner', 'owner', 'owner_name', ownerOptions, true),
        date('Expiry', 'expiry'),
        uuid('QR Code', 'uuid'),
    ], [locationOptions, ownerOptions, partNumberOptions]);

    const metadata: MetaContextType = React.useMemo(() => ({
        table: 'store',
        primary: 'id',
        single: 'item',
        zone: true,
        renderDecorator,
        renderItem: (row: any) => renderItem(row, download),
        headers: [
            { title: 'Description', name: 'description' },
            { title: 'Serial Number', name: 'serialnumber' },
            { title: 'Part Number', name: 'partnumber_name' },
            { title: 'Location', name: 'location_name' },
            {
                title: 'Status', name: 'status', render: (value: string, row: StoreItem) =>
                    <Typography level="body-xs">
                        {row.status === 'Serviceable' ?
                            row.form ?
                                <Link onClick={() =>
                                    download({ title: 'Form 1', name: 'form' }, row)}>
                                    {value}
                                </Link> :
                                '(NO FORM)' : value}
                    </Typography>
            },
            {
                title: 'Quantity', name: 'quantity', render: (value: string, row: StoreItem) =>
                    <Typography level="body-xs">
                        {value} / {row.original_quantity} {row.quantity_type}
                    </Typography>
            },
            { title: 'Owner', name: 'owner_name' },
            { title: 'Expiry', name: 'expiry' }
        ],
        uploads: [
            { title: 'Form 1', name: 'form' }
        ]
    }), [download]);

    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}
