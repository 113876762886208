import * as React from "react";
import Table from "../data/Table";
import { text } from "../data/fields/Fields";
import { StandardForm } from "../data/Editor";
import { FieldContext, MetaContext } from "../store/Context";
import { Typography } from '@mui/joy';

function renderDecorator(row: any) {
    return row.currency ? row.currency.charAt(0) : '?';
}

function renderItem(row: any) {
    return (
        <div>
            <Typography fontWeight={600} sx={{ width: '100%' }} gutterBottom>
                {row.currency}
            </Typography>
            <Typography level="body-xs">
                {row.name}
            </Typography>
        </div>
    );
}

const metadata = {
    primary: 'currency',
    table: 'currency',
    single: 'currency',
    zone: false,
    renderDecorator,
    renderItem,
    headers: [
        { title: 'Currency', name: 'currency' },
        { title: 'Name', name: 'name' },
    ]
}
const fields = [
    text('Currency', 'currency', true),
    text('Name', 'name', true),
];

export default function Currency() {
    return <MetaContext.Provider value={metadata}>
        <FieldContext.Provider value={fields}>
            <Table form={<StandardForm />} />
        </FieldContext.Provider>
    </MetaContext.Provider>;
}