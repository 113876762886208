import * as React from 'react';

import { useAuth } from 'react-oidc-context';
import { useMessage } from '../../App';
import { Header } from '../Types';
import { useMetadata } from '../../store/Context';
import { useTableData } from '../Table';

export function useHandleFileDownload(table: string, primary: string) {
    const message = useMessage();
    const auth = useAuth();
    const { access_token } = auth.user ?? {};

    return React.useCallback((upload: Header, row: any) => {
        async function downloadFile() {
            const headers = {
                Accept: "application/json",
                Authorization: `Bearer ${access_token}`,
            };
            let r = await fetch(`${process.env.REACT_APP_API}/download/${table}/${row[primary]}/${upload.name}`, { headers });
            if (!r.ok) throw new Error(`Something went wrong - ${r.status}`);

            const url = await r.text();
            r = await fetch(url);
            if (!r.ok) throw new Error(`Something went wrong - ${r.status}`);

            const form = await r.formData();
            const file: any = form.get('file');
            if (file == null) {
                throw new Error(`Something went wrong reading the file`);
            }
            const link = document.createElement('a');
            link.target = "_blank";
            link.href = URL.createObjectURL(file);
            link.click();
        }

        downloadFile()
            .catch((e: any) => {
                message('danger', e.toString());
                console.error(e);
            })

    }, [access_token, message, primary, table])
}

export function useHandleFileDelete() {
    const message = useMessage();
    const auth = useAuth();
    const { access_token } = auth.user ?? {};
    const { table, primary } = useMetadata();
    const { reload } = useTableData();

    return React.useCallback((upload: Header, row: any) => {
        async function deleteFile() {
            const headers = {
                Accept: "application/json",
                Authorization: `Bearer ${access_token}`,
            };
            let r = await fetch(`${process.env.REACT_APP_API}/delete/${table}/${row[primary]}/${upload.name}`, { headers });
            if (!r.ok) throw new Error(`Something went wrong - ${r.status}`)
            r = await fetch(`${process.env.REACT_APP_API}/${table}/${row[primary]}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify({ [upload.name]: 0 })
            })
            if (!r.ok) throw new Error(`Could not update file status - ${r.status}`)
            message('success', `Deleted ${upload.name}`);
            reload();
        }

        deleteFile()
            .catch((e: any) => {
                message('danger', e.toString());
                console.error(e);
            })

    }, [access_token, reload, message, primary, table])
}

export function useHandleFileUpload() {
    const message = useMessage();
    const auth = useAuth();
    const { access_token } = auth.user ?? {};
    const { table, primary } = useMetadata();
    const { reload } = useTableData();

    return React.useCallback((upload: Header, row: any) => {
        async function uploadFile(file: File | null) {
            if (file == null) {
                return;
            }
            const headers = {
                Accept: "application/json",
                Authorization: `Bearer ${access_token}`,
            };
            let r = await fetch(`${process.env.REACT_APP_API}/upload/${table}/${row[primary]}/${upload.name}`,
                {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify({ type: file.type, name: file.name })
                });
            if (!r.ok) throw new Error(`Something went wrong - ${r.status}`)

            const url = await r.text();
            const formData = new FormData();
            formData.append('file', file);
            r = await fetch(url, {
                method: 'PUT', body: formData,
            });
            if (!r.ok) throw new Error(`Something went wrong - ${r.status}`)

            r = await fetch(`${process.env.REACT_APP_API}/${table}/${row[primary]}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify({ [upload.name]: 1 })
            })
            if (!r.ok) throw new Error(`Could not update file status - ${r.status}`)
            message('success', `Uploaded ${file.name}`);
            reload();
        }

        const input = document.createElement("input");
        input.setAttribute('type', 'file');
        input.onchange = (e: any) =>
            uploadFile(e.target?.files?.item(0) ?? null)
                .catch((e: any) => {
                    message('danger', e.toString());
                    console.error(e);
                })
        input.click();

    }, [access_token, reload, message, primary, table])
}