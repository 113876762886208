import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { useParams } from "react-router-dom";


export default function QRCode() {
    const { area, code } = useParams();
    return <div style={{ textAlign: 'center' }}>
        <QRCodeSVG size={512} value={`https://app.modularaviation.com/${area}/${code}`} />
    </div>
}